module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chapatte CO Portfolio","short_name":"Chapatte CO","description":"Chapatte.CO, Swiss Web developer available for your next project","lang":"en","start_url":"/","background_color":"#1e1d28","icon":"src/assets/favicon.png","localize":[{"start_url":"/fr/","lang":"fr","short_name":"Chapatte CO","name":"Chapatte CO Portfolio","description":"Chapatte.CO, Un dévelopeur freelance de qualité pour votre prochain projet"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46181834-6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
